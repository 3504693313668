import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import { Container } from "../components/container";
import { Intro, TextSection } from "../components/Section";
import { Footer } from "../components/Footer";

import mDev from "../assets/MDEV4.svg";
import { Hero } from "../components/Hero";
import { Toggle } from "../components/Colorchange";
import { MainContent, openChat } from "../components/helpers";

class MDev extends React.Component
{
    render()
    {
        const siteTitle = get(this, "props.data.site.siteMetadata.title");
        const siteDescription = get(
            this,
            "props.data.site.siteMetadata.description"
        );

        return (
            <Layout location={ this.props.location }>
                <Helmet
                    htmlAttributes={ { lang: "en" } }
                    meta={ [{ name: "description", content: siteDescription }] }
                    title={ siteTitle }
                />
                <Container>
                    <Hero image={ mDev } imageSize={ "300px" }>
                        <h1>M DEV</h1>
                        <p>Infrastructure and Machine Learning experts</p>
                        <br/>
                        <Toggle onClick={ openChat } className="base btn-rainbow">Get in touch with us</Toggle>
                    </Hero>
                    <MainContent>
                        <Intro>
                            <h1>INFRASTRUCTURE</h1>
                            <p>Modern applications are complex and require the right experience to be managed and
                                maintained
                                in a secure, fast and efficient manner.</p>
                        </Intro>
                        <TextSection>
                            <h2>Performance</h2>
                            <p>A vital part of your customers user experience is performance. Every second spent loading
                                is
                                a negative impression on a user. We have the experience to architect the infrastructure
                                needed
                                to deliver the best performance possible.</p>
                        </TextSection>
                        <TextSection flow={ "right" }>
                            <h2>Security</h2>
                            <p>Security is a combination of code and people. We have the knowledge and experience to
                                design
                                the systems and implement the processes to keep both your data and your users data
                                secure.</p>
                        </TextSection>
                        <TextSection>
                            <h2>Efficiency</h2>
                            <p>Designing efficient infrastructure is vital to being able to scale when needed and
                                keeping
                                costs low. We architect infrastructure that is constantly tuned to adapt to your
                                application
                                needs. Whether you are just starting out or the next big trend, we make sure that your
                                infrastructure fits both the current you and the future you.</p>
                        </TextSection>
                        <TextSection flow={ "right" }>
                            <h2>Reliability</h2>
                            <p>Downtime and unreliability is detrimental to you and your users. We design systems with
                                the
                                technologies needed to make sure you are never down.</p>
                        </TextSection>
                    </MainContent>
                </Container>
                <Footer/>
            </Layout>
        );
    }
}

export default MDev;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;
